.delete-button {
    color: #1890ff;
    cursor: pointer;
}

.delete-button:hover {
    color: red
}

.delete-button:focus {
    color: red;
}

.input {
    margin-bottom: 20px;
}

.updateTypeSelect {
    min-width: 140px;
}

.buttonsContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 16px;
}

.canselButton {
    width: 90px;
}

.newReleaseModalItem {
    min-width: 231px;
}

.add-button{
    margin-left: auto;
}