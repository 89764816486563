.chartWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.chartWrapper> div{
    width: 49%;
    background-color: #FFFFFF;
    border-radius: 16px;
}

.pagerWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;
}
.pagerWrapper> div{
    width: 49%;
    background-color: #FFFFFF;
    border-radius: 16px;
}

.g2-html-annotation {
    z-index: 1 !important;
}