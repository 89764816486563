.login-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  height: 100vh;
}
.login-form form {
  width: 300px;
}
.login-form-button {
  width: 100%;
}
