.delete-button {
    color: #ee7080;
    cursor: pointer;
}

/* .delete-button:hover {
    color: '#ee7080';
}

.delete-button:focus {
    color: '#ee7080';
} */