/*.title {*/
/*    padding: 12px 0;*/
/*    font-size: 14px;*/
/*    font-weight: 700;*/
/*    color: #001629*/
/*}*/

/*.collapse {*/
/*    background-color: #FFF;*/
/*    border: 1px solid #F0F2F5;*/
/*}*/

/*.ant-collapse-header {*/
/*    font-size: 14px;*/
/*    font-weight: 700;*/
/*}*/
/*.ant-collapse-content {*/
/*    border-top: none;*/
/*}*/

/*.ant-collapse-content > .ant-collapse-content-box {*/
/*    padding-top: 0;*/
/*}*/

.description {
    border-radius: 8px;
    background-color: #F0F2F5;
    padding: 16px;
    margin-top: 45px;
}

.button {
    height: 32px;
    text-align: center;
    font-size: 14px;
    color: #448EF7;
    border-radius: 45px;
    margin-top: -30px;
}

