.titleText {
    padding-bottom: 25px;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #041427
}
.fieldName {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #5B5B5B;
}