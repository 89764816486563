.deactivated-row {
    background-color: rgba(238, 112, 128, 0.12);
}

.ant-table-tbody > tr.deactivated-row:hover > td {
    background-color: rgba(238, 112, 128, 0.12);
}

.ant-table-tbody > tr.deactivated-row > td.ant-table-column-sort {
    background: none;
}

.completed-campaign-row {
    background-color: rgba(255, 165, 0, 0.12);
}

.ant-table-tbody > tr.completed-campaign-row:hover > td {
    background-color: rgba(255, 140, 0, 0.12);
}

.deleted-campaign-row {
    background-color: rgba(238, 112, 128, 0.12);
}

.ant-table-tbody > tr.deleted-campaign-row:hover > td {
    background-color: rgba(238, 112, 128, 0.12);
}

.ant-select-auto-complete > .ant-select-clear {
    position: absolute;
    right: 42px;
    font-size: 15px;
}