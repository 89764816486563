.headerCard {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.titleText {
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #041427;
}

.infoText {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #59565C;
}

.imgWrapper {
    max-width: 235px;
    margin: auto;
    text-align: center;
}
.imgWrapper > img {
    margin-bottom: 20px;
}
.selectedButton {
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.passiveButton {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    background-color: #F4F3F7;
}

.bestDetails {
    position: relative;
    display: flex;
    flex-direction: row;
    max-width: 379px;
    width: 100%;
    margin: 21px auto 0;
    background-color: #F4F3F7;
    border-radius: 12px;
    padding: 11px 0 16px 16px;
}
.bestDetails > div:first-of-type {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.bestDetails > div > span{
  width: 102px;
  height: 102px;
  object-fit:cover;
}
.details {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
}

.details > span:first-of-type {
    width:100%;
    line-height: 22px;
    color: #448EF7;
    font-size: 16px;
    font-weight: 400;
}

.details> span:last-of-type {
    white-space: nowrap;
    width: 181px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 4px;
    line-height: 18px;
    font-weight: 400;
    font-size: 14px;
    color: #59565C;
}
.flag{
    position: relative;
    width: 54px;
    height: 22px;
    background: #FAAD14;
}
.best {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    padding: 3px 11px 11px 19px;
}
.flag >div::after {
    content: "";
    position: absolute;
    top: 22%;
    right: 60%;
    margin-top: -5px;
    border-width: 11px;
    border-style: solid;
    border-color: transparent #F4F3F7 transparent transparent;
    transform: rotate(180deg);
}

.chartImgWrapper {
    display: flex;
    justify-content: center;
}
.chartImgWrapper > img {
    height: 315px;
    width: 315px;
    padding-top: 10px;
}

.ant-card-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 15px 20px;
}