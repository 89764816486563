.ant-carousel .slick-dots li {
    background-color: white;
    box-shadow: 0 0 7px 3px #716f6f;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    overflow: hidden;
    margin: 0 5px;
}

.ant-carousel .slick-dots li button {
    width: 16px;
    height: 16px;
}

.ant-carousel .slick-dots li.slick-active {
    width: 16px;
    height: 16px;
    border-radius: 8px;
}

.rateTags {
    text-transform: lowercase;
    display: inline-block;
    padding: 2px 4px;
    margin: 5px 5px 5px 0;
    background-color: #efefef;
    border-radius: 3px;
}

.tagReview {
    white-space: break-spaces;
    margin: 0;
}

.transactionHistory {
    margin-top: 16px;
}

.transactionHistory p {
    margin: 0;
    font-weight: bold;
    color: #53a7ff;
    width: fit-content;
    cursor: pointer;
}
.transactionHistory p:hover {
    text-decoration: underline;
}
.orderDetails {
    background-color: #041427;
}
.cardTitle {
    text-align: center;
    color: #FBFAFA;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
}
.orderNumber {
    text-align: center;
    color: #FBFAFA;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}
.profile {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 8px;
    background: #F4F3F7;
    margin-bottom: 10px;
}
.name {
    margin-left: 12px;
    margin-bottom: 0;
    color: #041427;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}
.descriptionsTitle {
    color: #041427;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 12px;
}
.actionButton{
    padding: 4px 8px;
    margin-right: 8px;
    border: 1px solid #F0F2F5;
    border-radius: 4px;
    cursor: pointer;
}
.actionButtonDownload{
    padding: 4px 8px;
    border: 1px solid #F0F2F5;
    background-color: #F0F2F5;
    border-radius: 4px;
    cursor: pointer;
}