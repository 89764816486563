.infoWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 17px;
    margin-bottom: 29px;
}
.name {
    color: #001629;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 9px;
    margin-left: 0;
}
.email {
    color: #001629;
    font-size: 14px;
    font-weight: 400;
}

.collapse {
    background-color: #FFF;
    border: 1px solid #F0F2F5;
    margin-bottom: 16px;
}

.ant-collapse-item {
    border-bottom: none !important;
}

.ant-collapse-header {
    font-size: 14px;
    font-weight: 700;
    min-height: 50px;
}
.ant-collapse-content {
    border-top: none;
}

.ant-collapse-content > .ant-collapse-content-box {
    padding-top: 0;
}

.descriptionWrapper {
    border-radius: 8px;
    background-color: #F0F2F5;
    padding: 16px;
    margin-bottom: 8px;
}

.button {
    height: 32px;
    text-align: center;
    font-size: 14px;
    color: #448EF7;
    border-radius: 45px;
    margin-top: -30px;
}