.to-back-text {
    font-size: 16px;
    margin-left: 16px;
}

.to-back-container {
    width: fit-content;
}

.transactions-link {
    text-decoration: underline;
    font-weight: bold;
    color: #53a7ff;
    cursor: pointer;
}

.inviterImage {
    width: 55px;
    height: 55px;
    object-fit: contain;
}

.link {
    color: black;
    border-bottom: 1px solid black;
}

.title {
    color: #59565C;
    font-weight: 500;
    padding: 5px 0;
}

.selfRegisteredUser {
    margin-top: 8px;
    color: #A29DB0;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}
